import CountdownTimer from '../javascript/countdown/CountdownTimer'

const timerElements = Array.from(document.querySelectorAll('[data-timer]'))

let completeEvent = document.createEvent('Event')
completeEvent.initEvent('countdown:complete', true, true)

timerElements.forEach((element) => {
  const { date } = element.dataset
  const completeContentElement = element.querySelector(
    '[data-complete-content]',
  )

  const timer = new CountdownTimer(element, date, (el) => {
    element.dispatchEvent(completeEvent)
    if (completeContentElement) {
      el.parentNode.innerHTML = completeContentElement.innerHTML
    }
  })

  timer.start()
})
