import moment from 'moment'

export default class CountdownTimer {
  constructor(element, date, onComplete) {
    this.endDate = moment(date, "YYYY-MM-DD HH:mm:ss ZZ")
    this.granularity = 1000
    this.running = false
    this.element = element
    this.timer = {}
    this.onComplete = onComplete
    this.tick = this.tick.bind(this)
  }

  start() {
    if (this.running) {
      return
    }

    this.running = true
    this.tick()
    this.timer = setInterval(this.tick, this.granularity)
  }

  tick() {
    let now = moment()
    let distance = this.endDate.diff(now)

    if (distance <= 0) {
      clearInterval(this.timer)
      this.running = false
      this.onComplete(this.element)
      return
    }

    if (this.element) {
      this.setDisplay(distance, this.element)
    }
  }

  setDisplay(distance, element) {
    let _second = 1000
    let _minute = _second * 60
    let _hour = _minute * 60
    let _day = _hour * 24

    let days = Math.floor(distance / _day)
    let hours = Math.floor((distance % _day) / _hour)
    let minutes = Math.floor((distance % _hour) / _minute)
    let seconds = Math.floor((distance % _minute) / _second)

    var output = days + 'D ' + hours + 'H ' + minutes + 'M ' + seconds + 'S'

    element.textContent = output
  }
}
